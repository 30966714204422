<template>
	<section>
		<b-form>
			<b-row>
				<b-col
					cols="12"
					md="6"
					class="mb-1"
				>
					<label>
						<strong>DOCUMENTOS GENERALES</strong>
					</label>
					<b-button-upload
						entity="REQUESTS"
						:entity-id="requestId"
						:is-disabled="requestStateId !== RequestStates.EN_EDICION"
						:request-props="{ isGeneral: true }"
						:modal-props="{ title: 'DOCUMENTOS GENERALES' }"
						:button-props="{ text: requestStateId !== RequestStates.EN_EDICION ? 'VER DOCUMENTOS' : 'CARGAR DOCUMENTOS', block : true }"
						:any-file="true"
					/>
				</b-col>
				<b-col
					cols="12"
					md="6"
					class="mb-1"
				>
					<label>
						<strong>DOCUMENTOS ESPECIFICOS</strong>
					</label>
					<b-button-upload
						entity="REQUESTS"
						:request-props="{ isSpecific: true }"
						:modal-props="{ title: 'DOCUMENTOS ESPECÍFICOS' }"
						:button-props="{ text: 'VER DOCUMENTOS', block : true }"
					/>
				</b-col>
			</b-row>
		</b-form>
	</section>
</template>

<script>
import BButtonUpload from "@core/components/b-button-upload/BButtonUpload.vue"
import { RequestStates } from "@core/utils/data"
export default {
	components: {
		BButtonUpload
	},
	props: {
		requestId: {
			type: Number,
			required: true
		},
		requestStateId: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			RequestStates
		}
	}
}
</script>